var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inhalt",class:_vm.$mq},[_c('div',{staticClass:"mehrinfo",class:_vm.$mq},[_c('div',{staticClass:"text"},[_vm._v("MEHR INFOS")])]),_c('div',{staticClass:"ast",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/inhalt_ast.png")}})]),_c('div',{staticClass:"inhalt__inhalt-content"},[_c('div',{staticClass:"heading"},[_c('div',{staticClass:"content"},[_c('video',{attrs:{"src":require("@/assets/videos/clip_inhalt.mp4"),"loop":"","muted":"","autoplay":""},domProps:{"muted":true}})])])]),_c('div',{staticClass:"inhalt__wrapper",class:_vm.$mq,attrs:{"id":"inhalt"}},[_c('div',{staticClass:"inhalt-inhalt"},[_c('div',{staticClass:"inhalt-texte"},[_c('div',{staticClass:"ast2",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/inhalt_ast_2.png")}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.stop",value:({
            duration: 1000,
            delay: 250,
            origin: 'bottom',
            distance: '40px',
          }),expression:"{\n            duration: 1000,\n            delay: 250,\n            origin: 'bottom',\n            distance: '40px',\n          }",modifiers:{"stop":true}}],staticClass:"inhalt-cites",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/inhalt_cline.png")}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.stop",value:({
            duration: 1000,
            delay: 450,
            origin: 'bottom',
            distance: '40px',
          }),expression:"{\n            duration: 1000,\n            delay: 450,\n            origin: 'bottom',\n            distance: '40px',\n          }",modifiers:{"stop":true}}],staticClass:"texte"},[_c('div',{staticClass:"text-1",class:_vm.$mq},[_c('span',[_vm._v("GUNDA ist Kino pur.")]),_vm._v(" Der Film zeigt das Leben eines Hausschweins, einer Hühnerschar und einer Rinderherde mit"),_c('br'),_vm._v(" meisterhafter Intensität. In herausragenden Schwarz-Weiß-Bildern begegnet Regisseur Victor Kossakovsky den tierischen"),_c('br'),_vm._v(" Bewohnern auf einem kleinen Bauernhof auf Augenhöhe und erzählt sie als Geschöpfe mit eigener Wahrnehmung, eigenem"),_c('br'),_vm._v(" Empfinden und eigenen Gewohnheiten – eine meditative Reise in das Leben seiner Protagonisten und ihren Kosmos mit"),_c('br'),_vm._v(" seinem ganz eigenen Raum- und Zeitgefüge. ")]),_c('div',{staticClass:"text-2",class:_vm.$mq},[_vm._v(" GUNDA ermöglicht uns, über das Geheimnis tierischen Bewusstseins nachzudenken und darüber, welche Rolle wir"),_c('br'),_vm._v(" Menschen dabei spielen: ein poetisches Plädoyer für das Recht auf Leben der Lebewesen, die wir sonst nur als"),_c('br'),_vm._v(" „Nutztiere“ wahrnehmen. Der richtige Film zur richtigen Zeit. ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }