<!-- @format -->

<template>
  <div :id="id" class="nova-card">
    <div
      class="nova-card__content"
      :style="cardContentStyle"
      :class="{ 'is-open': open }"
    >
      <div class="nova-card__header" @click="openCard">
        <div class="nova-card__bg" :style="imageStyle" />
        <div class="nova-card__teaser-wrapper">
          <h1 v-if="$slots.headline" :style="headlineStyle">
            <slot name="headline" />
          </h1>
          <div v-if="$slots.teaser && !open" class="nova-card__teaser">
            <slot name="teaser" />
          </div>
          <div class="nova-card__btn-wrapper" :style="buttonWrapperStyle">
            <div
              v-if="!open"
              class="nova-card__btn-open"
              :style="buttonTopStyle"
            >
              <PlusIcon /> {{ openButton }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="open"
        class="nova-card__btn-close"
        :style="closeStyle"
        @click="closeCard"
      >
        <Close />
      </div>
      <div v-if="open" class="nova-card__open-card">
        <slot />
        <div
          class="nova-card__btn-bottom-wrapper"
          :style="buttonBottomWrapperStyle"
        >
          <div
            v-if="open"
            class="nova-card__btn-bottom-close"
            :style="buttonBottomStyle"
            @click="closeCard"
          >
            <MinusIcon /> {{ closeButton }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PlusIcon from 'vue-material-design-icons/PlusCircle.vue'
  import MinusIcon from 'vue-material-design-icons/MinusCircle.vue'
  import Close from 'vue-material-design-icons/CloseCircle'

  export default {
    components: {
      PlusIcon,
      MinusIcon,
      Close,
    },
    props: {
      close: {
        type: String,
        default: 'X',
      },
      'close-color': {
        type: String,
        default: 'white',
      },
      'close-button': {
        type: String,
        default: 'Schliessen',
      },
      'open-button': {
        type: String,
        default: 'Weiterlesen',
      },
      image: {
        type: String,
        default: 'https://via.placeholder.com/500x400.png',
      },
      'image-height': {
        type: Number,
        default: 300,
      },
      'image-align': {
        type: String,
        default: 'top',
      },
      'headline-fontsize': {
        type: String,
        default: '32px',
      },
      'headline-lineheight': {
        type: String,
        default: '1',
      },
      'headline-marginbottom': {
        type: String,
        default: '15px',
      },
      'teaser-padding': {
        type: String,
        default: '5%',
      },
      'btn-top-bg': {
        type: String,
        default: 'black',
      },
      'btn-top-color': {
        type: String,
        default: 'white',
      },
      'btn-top-align': {
        type: String,
        default: 'right',
      },
      'btn-bottom-bg': {
        type: String,
        default: 'black',
      },
      'btn-bottom-color': {
        type: String,
        default: 'white',
      },
      'btn-bottom-align': {
        type: String,
        default: 'right',
      },
      'card-bg': {
        type: String,
        default: '#fff',
      },
      'card-margin': {
        type: String,
        default: '5%',
      },
      'card-border-radius': {
        type: String,
        default: '20px',
      },
      'card-content-padding': {
        type: String,
        default: '5%',
      },
    },
    data() {
      return {
        open: false,
        id: 'nova-card__' + Math.ceil(Math.random() * 12345),
        top: 0,
        height: 0,
        tmp: () => {},
        cm: this.cardMargin,
        cbr: this.cardBorderRadius,
      }
    },
    computed: {
      closeStyle() {
        return `color: ${this.closeColor}`
      },
      cardImageHeight() {
        return this.$store.getters['Window/isSize'].width < 700
          ? this.imageHeight
          : this.imageHeight + 200
      },
      cardContentStyle() {
        return `backgroundColor: ${this.cardBg};
                    margin: 0 ${this.cm};
                    borderRadius: ${this.cbr};
                    --padding: ${this.cardContentPadding};`
      },
      imageStyle() {
        return (
          `height: ${this.cardImageHeight}px;
                    background: transparent url(` +
          require('@/assets/images/cards/' + this.image) +
          `) no-repeat;
                    borderTopLeftRadius: ${this.cardBorderRadius};
                    borderTopRightRadius: ${this.cardBorderRadius};
                    backgroundPositionX: center;
                    backgroundPositionY: ${this.imageAlign};`
        )
      },
      headlineStyle() {
        return `fontSize: ${this.headlineFontsize};
                    lineHeight: ${this.headlineLineheight};
                    marginBottom: ${this.headlineMarginbottom};`
      },
      buttonWrapperStyle() {
        return `textAlign: ${this.btnTopAlign};`
      },
      buttonTopStyle() {
        return `backgroundColor: ${this.btnTopBg};
                    color: ${this.btnTopColor};`
      },
      buttonBottomWrapperStyle() {
        return `textAlign: ${this.btnBottomAlign};`
      },
      buttonBottomStyle() {
        return `backgroundColor: ${this.btnBottomBg};
                    color: ${this.btnBottomColor};
                    font-weight: 700;
                    text-transform: uppercase;`
      },
    },
    methods: {
      openCard() {
        if (!this.open) {
          this.tmp = { margin: this.cardMargin, radius: this.cardBorderRadius }
          this.cm = '0'
          this.cbr = '0'
          let el = document.getElementById(this.id)
          this.open = true
          this.top = -el.getBoundingClientRect().top
          el.style.transform = `translate3d(0px, ${this.top}px, 0px)`
          el.style.zIndex = '9999'
          el.children[0].style.transform = `translate3d(0px, 0px, 0px)`
          var self = this
          let root = document.getElementsByTagName('html')[0]
          root.setAttribute('class', 'stop-scrolling')
          setTimeout(function () {
            self.height = el.children[0].offsetHeight
            el.children[0].style.height = window.innerHeight + 'px'
            el.style.height = self.height - self.top + 'px'
          }, 100)
        }
      },
      closeCard() {
        this.open = false
        this.cm = this.tmp.margin
        this.cbr = this.tmp.radius
        let el = document.getElementById(this.id)
        this.top = el.getBoundingClientRect().top
        el.style.removeProperty('transform')
        el.style.zIndex = '10'
        el.style.height = 'auto'
        el.children[0].style.height = 'auto'
        let root = document.getElementsByTagName('html')[0]
        root.removeAttribute('class', 'stop-scrolling')
      },
    },
  }
</script>

<style lang="scss">
  .stop-scrolling {
    height: 100% !important;
    overflow: hidden !important;
  }
  .is-open {
    overflow-y: scroll;
  }

  .is-open::-webkit-scrollbar {
    display: none;
  }

  .nova-card {
    z-index: 10;
    --padding: 5%;
    margin: 30px auto 0 auto;
    $self: &;
    position: relative;
    transition-property: padding, height, transform, border-radius,
      -webkit-transform;
    transition-duration: 1s;
    background-color: transparent;
    overflow-y: scroll;
    max-width: 770px;
    span {
      font-weight: 900;
    }
    &__p {
      margin: 0 0 15px 0;
    }
    &__content {
      position: relative;
      z-index: 280;
      transition-property: margin, -webkit-transform;
      transition-duration: 1s;
      #{$self}__header {
        position: relative;
        #{$self}__bg {
          position: relative;
          background-size: cover !important;
        }
        #{$self}__teaser-wrapper {
          padding: var(--padding) var(--padding) 0 var(--padding);
          h1 {
            margin: 0;
            text-transform: uppercase;
          }
          #{$self}__teaser {
            margin-bottom: 15px;
          }
          #{$self}__btn-wrapper {
            width: 100%;
            #{$self}__btn-open {
              margin-bottom: var(--padding);
              display: inline-block;
              border-radius: 8px;
              font-weight: 700;
              text-transform: uppercase;
            }
          }
        }
      }
      #{$self}__btn-close {
        text-align: center;
        z-index: 300;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 40px;
      }
      #{$self}__open-card {
        padding: 0 var(--padding);
        h1 {
          line-height: 1;
          color: $h1;
          font-size: 1.5rem;
        }
        #{$self}__btn-bottom-wrapper {
          margin-top: 20px;
          width: 100%;
          #{$self}__btn-bottom-close {
            cursor: pointer;
            //padding: 5px 15px;
            margin-bottom: var(--padding);
            display: inline-block;
            border-radius: 8px;
          }
        }
      }
    }
  }

  @function strip-unit($value) {
    @return $value / ($value * 0 + 1);
  }

  @mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
      & {
        font-size: $min-font-size;
        @media screen and (min-width: $min-vw) {
          font-size: calc(
            #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          );
        }
        @media screen and (min-width: $max-vw) {
          font-size: $max-font-size;
        }
      }
    }
  }
</style>
