<!-- @format -->

<template>
  <div class="inhalt" :class="$mq">
    <div class="mehrinfo" :class="$mq">
      <div class="text">MEHR INFOS</div>
    </div>
    <div class="ast" :class="$mq">
      <img src="@/assets/images/inhalt_ast.png" />
    </div>
    <div class="inhalt__inhalt-content">
      <div class="heading">
        <div class="content">
          <video
            src="@/assets/videos/clip_inhalt.mp4"
            loop
            muted
            autoplay
          ></video>
        </div>
      </div>
    </div>
    <div class="inhalt__wrapper" :class="$mq" id="inhalt">
      <div class="inhalt-inhalt">
        <div class="inhalt-texte">
          <div class="ast2" :class="$mq">
            <img src="@/assets/images/inhalt_ast_2.png" />
          </div>
          <div
            class="inhalt-cites"
            :class="$mq"
            v-scroll-reveal.stop="{
              duration: 1000,
              delay: 250,
              origin: 'bottom',
              distance: '40px',
            }"
          >
            <img src="@/assets/images/inhalt_cline.png" />
          </div>

          <div
            class="texte"
            v-scroll-reveal.stop="{
              duration: 1000,
              delay: 450,
              origin: 'bottom',
              distance: '40px',
            }"
          >
            <div class="text-1" :class="$mq">
              <span>GUNDA ist Kino pur.</span> Der Film zeigt das Leben eines
              Hausschweins, einer Hühnerschar und einer Rinderherde mit<br />
              meisterhafter Intensität. In herausragenden Schwarz-Weiß-Bildern
              begegnet Regisseur Victor Kossakovsky den tierischen<br />
              Bewohnern auf einem kleinen Bauernhof auf Augenhöhe und erzählt
              sie als Geschöpfe mit eigener Wahrnehmung, eigenem<br />
              Empfinden und eigenen Gewohnheiten – eine meditative Reise in das
              Leben seiner Protagonisten und ihren Kosmos mit<br />
              seinem ganz eigenen Raum- und Zeitgefüge.
            </div>
            <div class="text-2" :class="$mq">
              GUNDA ermöglicht uns, über das Geheimnis tierischen Bewusstseins
              nachzudenken und darüber, welche Rolle wir<br />
              Menschen dabei spielen: ein poetisches Plädoyer für das Recht auf
              Leben der Lebewesen, die wir sonst nur als<br />
              „Nutztiere“ wahrnehmen. Der richtige Film zur richtigen Zeit.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  .inhalt {
    $self: &;
    position: relative;
    background-color: white;
    .mehrinfo {
      font-family: 'Lora';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: auto;
      top: -48px;
      background-color: white;
      height: 66px;
      width: 200px;
      &.lg {
        top: -40px;
        height: 46px;
        width: 150px;
        .text {
          font-size: 18px;
        }
      }
      .text {
        margin-top: 12px;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
      }
    }
    .ast {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 31%;
      img {
        width: 100%;
      }
      &.lg {
        display: none;
      }
      &.xl {
        left: -190px;
      }
    }
    &__wrapper {
      overflow: hidden;
      height: 383px;
      width: 100%;
      position: relative;
      text-align: center;
      font-family: 'Lora';

      &.lg,
      &.xl {
        background-image: none;
      }
      .inhalt-texte {
        .ast2 {
          position: absolute;
          top: 0px;
          z-index: 999;
          right: 0;
          width: 31%;
          img {
            width: 100%;
          }
          &.lg {
            display: none;
          }
          &.xl {
            right: -190px;
            top: 0px;
          }
        }
        .inhalt-cites {
          padding-top: 40px;
          width: 22%;
          margin: 0 auto;
          &.lg {
            width: 30%;
          }
          img {
            width: 100%;
          }
        }
        .text-1 {
          margin-top: 30px;
          font-size: 16px;
          span {
            font-weight: 700;
          }
        }
        .text-2 {
          font-weight: 700;
          font-size: 16px;
          margin-top: 30px;
        }
      }
    }
  }
</style>
