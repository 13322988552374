var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crew",class:_vm.$mq,attrs:{"id":"crew"}},[_c('div',{staticClass:"crew__wrapper"},[_c('div',{staticClass:"crew-inhalt"},[_c('div',{staticClass:"crew-text",class:_vm.$mq},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.stop",value:({
            duration: 1000,
            delay: 650,
            origin: 'bottom',
            distance: '40px',
          }),expression:"{\n            duration: 1000,\n            delay: 650,\n            origin: 'bottom',\n            distance: '40px',\n          }",modifiers:{"stop":true}}],staticClass:"head",class:_vm.$mq},[_vm._v(" CREW ")]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.stop",value:({
            duration: 1000,
            delay: 850,
            origin: 'bottom',
            distance: '40px',
          }),expression:"{\n            duration: 1000,\n            delay: 850,\n            origin: 'bottom',\n            distance: '40px',\n          }",modifiers:{"stop":true}}],staticClass:"leute"},[_c('div',{staticClass:"person",class:_vm.$mq},[_c('span',[_vm._v("Victor Kossakovsky")]),_c('br'),_vm._v(" Buch, Regie & Schnitt ")]),_c('div',{staticClass:"person",class:_vm.$mq},[_c('span',[_vm._v("Anita Rehoff Larsen")]),_c('br'),_vm._v(" Produzentin ")]),_c('div',{staticClass:"person",class:_vm.$mq},[_c('span',[_vm._v("Joaquin Phoenix, Tone Grøttjord-Glenne")]),_c('br'),_vm._v(" Ausführende Produzenten ")]),_c('div',{staticClass:"person",class:_vm.$mq},[_c('span',[_vm._v("Joslyn Barnes & Susan Rockefeller")]),_c('br'),_vm._v(" Koproduzentinnen ")]),_c('div',{staticClass:"person",class:_vm.$mq},[_c('span',[_vm._v("Egil Haskjold Larsen & Victor Kossakovsky")]),_c('br'),_vm._v(" Kamera ")]),_c('div',{staticClass:"person",class:_vm.$mq},[_c('span',[_vm._v("Alexandr Dudarev")]),_c('br'),_vm._v(" Sound-Design & -Schnitt ")])]),_c('div',{staticClass:"bild-crew",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/cast-image.png")}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }