<template>
  <div id="fotos">
    <div class="content-fotos">
      <div class="row">
        <div class="column rand">
          <img src="@/assets/images/foto_1.jpg" @click="openGallery(0)" />
        </div>
        <div class="column rand-2">
          <video
            src="@/assets/videos/bewegtbild_1.mp4"
            loop
            muted
            autoplay
          ></video>
        </div>
        <div class="column rand">
          <img src="@/assets/images/foto_3.jpg" @click="openGallery(1)" />
        </div>
      </div>
      <div class="row">
        <div class="column rand">
          <img src="@/assets/images/foto_4.jpg" @click="openGallery(2)" />
        </div>
        <div class="column rand-2 rand">
          <img src="@/assets/images/foto_5.jpg" @click="openGallery(3)" />
        </div>
        <div class="column rand">
          <img src="@/assets/images/foto_6.jpg" @click="openGallery(4)" />
        </div>
      </div>
      <div class="row">
        <div class="column rand">
          <img src="@/assets/images/foto_7.jpg" @click="openGallery(5)" />
        </div>
        <div class="column rand-2">
          <video
            src="@/assets/videos/bewegtbild_2.mp4"
            loop
            muted
            autoplay
          ></video>
        </div>
        <div class="column rand">
          <img src="@/assets/images/foto_9.jpg" @click="openGallery(6)" />
        </div>
      </div>
    </div>
    <BaseModalGallery :index="index" />
  </div>
</template>
<script>
  export default {
    /*    props: {
      gallery: {
        type: Boolean,
        default: false,
      },
    },*/
    data() {
      return {
        index: null,
      }
    },
    methods: {
      openGallery(number) {
        this.index = number
      },
    },
  }
</script>

<style lang="scss">
  .content-fotos {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: auto;
    .column {
      float: left;
      width: 33.2%;
      overflow: hidden;
      img,
      video {
        cursor: pointer;
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: transform 1s ease-out;
        -moz-transition: transform 1s ease-out;
        -ms-transition: transform 1s ease-out;
        transition: transform 1s ease-out;

        &:hover {
          -moz-transform: scale(1.05);
          -webkit-transform: scale(1.05);
          -o-transform: scale(1.05);
          -ms-transform: scale(1.05);
          -webkit-transform: scale(1.05);
          transform: scale(1.05);
        }
      }
    }

    /* Clear floats after image containers */
    .row::after {
      content: '';
      clear: both;
      display: table;
    }
    .rand {
      border-bottom: 1px solid white;
    }
    .rand-2 {
      border-left: 1px solid white;
      border-right: 1px solid white;
    }
  }
</style>
