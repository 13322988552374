<!-- @format -->

<template>
  <footer class="footer" :class="$mq">
    <div class="ast" :class="$mq">
      <img src="@/assets/images/footer_ast.png" />
    </div>
    <div class="ast2" :class="$mq">
      <img src="@/assets/images/footer_ast_2.png" />
    </div>
    <div class="footer__content">
      <div class="footer__tt" :class="$mq">
        <img :src="require(`@/assets/images/` + $config.tt_small)" alt="" />
      </div>
      <BaseKinostart :kinostart="kinostart" />
      <div class="underline-footer" :class="$mq">
        Ab 30.11. als digitaler Download
      </div>
      <!-- SECTION Footer Company -> Chooses Footer by $config.verantwortlich -> look @ computed -->
      <component
        class="footer__company"
        :is="companyFooter"
        :teilnahmebedingung="teilnahmebedingung"
      ></component>
    </div>
    <div class="footer__billing" :class="$mq">
      <img src="@/assets/images/billing.png" alt="" />
    </div>
    <div class="cb-restore"></div>
  </footer>
</template>

<script>
  export default {
    props: {
      billing: {
        type: Boolean,
        default: true,
      },
      youtubenote: {
        type: Boolean,
        default: true,
      },
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      teilnahmebedingung: {
        type: String,
        default: 'leer',
      },
    },
    computed: {
      companyFooter() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Footer.vue`
          )
      },
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
  }
</script>

<style lang="scss">
  .footer {
    $self: &;
    position: relative;
    text-align: center;
    height: 527px;
    font-size: 0.875rem;
    width: 100%;
    padding-bottom: 5rem;
    &.sm,
    &.md {
      background: none;
      background-color: white;
    }
    &.lg {
      height: 527px;
    }
    &.xl {
      height: 527px;
    }
    .ast {
      position: absolute;
      top: -70px;
      left: 0;
      width: 31%;
      img {
        width: 100%;
      }
      &.lg {
        display: none;
      }
      &.xl {
        left: -190px;
      }
      &.md,
      &.sm {
        display: none;
      }
    }
    .ast2 {
      position: absolute;
      top: -130px;
      right: 0px;
      width: 26%;
      img {
        width: 100%;
      }
      &.lg {
        display: none;
      }
      &.xl {
        right: 0px;
        width: 23%;
      }
      &.md,
      &.sm {
        display: none;
      }
    }
    &__content {
      position: relative;
      margin: 0 auto;
      padding-top: 2rem;
      #{$self}__tt {
        margin: 40px auto 20px auto;
        &.sm,
        &.md {
          padding: 0 5%;
          margin: 10px auto 0 auto;
        }
      }
      .base-kinostart {
        color: black;
        text-transform: uppercase;
        &.lg,
        &.xl,
        &.xxl {
          font-weight: 500;
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 35px;
          $max_font: 45px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
        &.sm,
        &.md {
          padding-top: 15px;
          $min_width: 300px;
          $max_width: 1023px;
          $min_font: 22px;
          $max_font: 45px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
      }
      .underline-footer {
        color: black;
        padding: 0 0 20px 0;
        width: 100%;
        text-align: center;
        font-family: Lora;
        $min_width: 300px;
        $max_width: 1024px;
        $min_font: 15px;
        $max_font: 30px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
        &.lg {
          $min_width: 300px;
          $max_width: 1024px;
          $min_font: 10px;
          $max_font: 20px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
        &.md,
        &.sm {
          $min_width: 300px;
          $max_width: 1024px;
          $min_font: 15px;
          $max_font: 42px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
      }
    }
    #{$self}__billing {
      margin-top: 15px;
      &.sm,
      &.md {
        margin-top: 0;
      }
      &.sm,
      &.md,
      &.lg,
      &.xl {
        padding: 0 5%;
      }
    }
  }
</style>
