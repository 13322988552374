<!-- @format -->

<template>
  <div>
    <DesktopHeader />
    <DesktopTop :ratio="ratio" :trailer="trailer" :kinostart="kinostart" />
    <Inhalt />
    <Fotos />
    <Crew />
    <!--<Hintergrundinfos />-->
    <!--<Regie />-->
    <!-- <Inhalt />
    <Fotos />
    <Timeline />
    <Statement />
    <Clip />
    <Partner /> -->
    <BaseFooter :billing="true" :kinostart="kinostart" />
    <BaseYoutube :youtube="$config.youtube" :margin="100" />
  </div>
</template>

<script>
  import Inhalt from '@/views/Inhalt.vue'
  import Fotos from '@/views/Fotos.vue'
  import Crew from '@/views/Crew.vue'
  /* 
  import Hintergrundinfos from '@/views/Hintergrundinfos.vue'
  import Regie from '@/views/Regie.vue'
  import Timeline from '@/views/Timeline.vue'
  import Statement from '@/views/Statement.vue'
  import Clip from '@/views/Clip.vue'
  import Partner from '@/views/Partner.vue' */
  import moment from 'moment'

  export default {
    components: {
      Inhalt,
      Fotos,
      Crew,
      /*  Hintergrundinfos,
      Regie, 
      Fotos,
      Timeline,
      Statement,
      Clip,
      Partner */
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
      trailer() {
        return this.$config.youtube.id !== ''
      },
    },
    methods: {
      logout() {
        this.$store.dispatch('Auth/userSignOut')
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
