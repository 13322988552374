var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"pointer-events":"none","position":"relative"},attrs:{"id":"index"}},[_c('div',{staticClass:"desktop-top",class:_vm.$mq},[_c('div',{staticClass:"desktop-top__wrapper"},[_c('div',{staticClass:"desktop-top__praedi",class:_vm.$mq},[_vm._m(0),_c('img',{attrs:{"src":require("@/assets/images/praedi.png")}})]),_c('div',{staticClass:"desktop-top__gewinner",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/gewinn.png")}})]),_c('div',{staticClass:"desktop-top__image",class:_vm.$mq}),_c('div',{staticClass:"desktop-top__content",class:_vm.$mq},[_c('div',{staticClass:"desktop-top__packshot",class:_vm.$mq},[_vm._m(1)]),_c('div',{staticClass:"desktop-top__tt",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/" + _vm.$config.tt),"alt":""}})]),_c('div',{staticClass:"desktop-top__trailer",class:_vm.$mq},[(_vm.$config.youtube.display)?_c('div',[_c('div',{staticClass:"open-trailer",staticStyle:{"position":"relative"},on:{"click":_vm.ytVideo}},[_c('video',{attrs:{"src":require("@/assets/videos/sizzle.mp4"),"loop":"","muted":"","autoplay":""},domProps:{"muted":true}}),_c('div',{staticClass:"trailer-misc"},[_c('img',{staticClass:"play-btn",style:([
                    _vm.ratio < 1.73
                      ? {
                          width: '40px',
                        }
                      : {
                          width: '70px',
                        } ]),attrs:{"src":require("@/assets/images/trailer/play.png"),"alt":""}}),_c('div',{staticClass:"trailer-text"},[_vm._v("Trailer ansehen")])])])]):_vm._e()]),_c('BaseKinostart',{attrs:{"kinostart":_vm.kinostart}}),_c('div',{staticClass:"underline-text",class:_vm.$mq},[_vm._v(" Ab 30.11. als digitaler Download ")]),_c('div',{staticClass:"btns_top",class:_vm.$mq},[_c('div',{staticClass:"amazon_top",class:_vm.$mq},[_vm._m(2)]),_c('div',{staticClass:"itunes_top",class:_vm.$mq},[_vm._m(3)])])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stoerer"},[_c('a',{attrs:{"href":"download/Gunda_Bildungsmaterial.pdf","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/Störer.png"),"alt":""}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://www.amazon.de/-/de/Gunda/dp/B09CRLZLGQ/ref=sr_1_3?dchild=1&keywords=Gunda+DVD&qid=1633335459&qsid=262-1034553-2302661&sr=8-3&sres=B09CRLZLGQ%2CB01M3S2HM1%2CB00ABHQFS8%2CB006XEU6VA%2CB08BG5H8XY%2CB00EZMEHQ2%2C3110486814%2CB01MYULCEC%2CB00AAZ3THG%2CB01LP5FCP6%2CB07KZ4H9KR%2CB07BZC8LBB%2CB0785MQP8W%2CB01M4N0UCH%2CB07T2BGV5V%2CB00QV47KM0","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/packshot.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://www.amazon.de/Gunda/dp/B09CRLZLGQ/ref=sr_1_2?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Gunda+DVD&qid=1635174223&qsid=262-1034553-2302661&s=dvd&sr=1-2&sres=B09CRLZLGQ%2CB09794X5RG%2CB01M3S2HM1%2CB00ABHQFS8%2CB00ABHQFW4%2CB017RGO61S%2CB006XEU6VA%2CB08BG5H8XY%2CB00EZMEHQ2%2CB07T2BGV5V%2CB01MPWGLUW%2CB00AAZ3THG%2CB07H2Z3PLV%2CB01M4N0UCH%2CB01MXVVDXG%2CB01LP5FCP6","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/amazon_top.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://tv.apple.com/de/movie/gunda/umc.cmc.6qm3lmiek1mvla9c400e9aljb","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/itunes_top.png"),"alt":""}})])}]

export { render, staticRenderFns }