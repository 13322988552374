<!-- @format -->
<!-- @format -->
<template>
  <a
    v-if="$config.youtube.display === true"
    :href="$config.youtube.link"
    target="_blank"
    class="m-trailer external"
  >
    <div class="m-trailer__wrapper">
      <video
        src="@/assets/videos/sizzle.mp4"
        loop
        muted
        autoplay
        playsinline
      ></video>
      <div class="m-trailer__text-wrapper">
        <img src="@/assets/images/trailer/play.png" alt="" />
        <div class="m-trailer__text">Trailer ansehen</div>
      </div>
    </div>

    <p class="m-trailer__note">
      Quelle: Youtube<br />
      Zum Ansehen des Videos müssen Sie auf den Play Button klicken. Wir möchten
      Sie darauf hinweisen, dass nach der Aktivierung Daten an Youtube
      übermittelt werden.
    </p>
  </a>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  .m-trailer {
    $self: &;
    position: relative;
    background: #030204;
    padding: 5% 5% 0% 5%;
    display: block;
    width: 90%;
    &__wrapper {
      position: relative;
      cursor: pointer;
      video {
        width: 100%;
      }
      #{$self}__text-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 111;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        #{$self}__text {
          text-align: center;
          font-weight: 400;
          color: white;
          text-transform: uppercase;
          margin-top: 1rem;
        }
      }
    }
    &__note {
      text-align: center;
      font-size: 0.8rem;
      color: #878787;
      margin-top: 1rem;
      font-weight: 400;
    }
  }
</style>
