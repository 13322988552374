<!-- @format -->

<template>
  <div id="index" style="pointer-events: none; position: relative">
    <div class="desktop-top" :class="$mq">
      <div class="desktop-top__wrapper">
        <div class="desktop-top__praedi" :class="$mq">
          <div class="stoerer">
            <a href="download/Gunda_Bildungsmaterial.pdf" target="_blank"
              ><img src="@/assets/images/Störer.png" alt=""
            /></a>
          </div>
          <img src="@/assets/images/praedi.png" />
        </div>
        <div class="desktop-top__gewinner" :class="$mq">
          <img src="@/assets/images/top/gewinn.png" />
        </div>
        <div class="desktop-top__image" :class="$mq"></div>
        <div class="desktop-top__content" :class="$mq">
          <div class="desktop-top__packshot" :class="$mq">
            <a
              href="https://www.amazon.de/-/de/Gunda/dp/B09CRLZLGQ/ref=sr_1_3?dchild=1&keywords=Gunda+DVD&qid=1633335459&qsid=262-1034553-2302661&sr=8-3&sres=B09CRLZLGQ%2CB01M3S2HM1%2CB00ABHQFS8%2CB006XEU6VA%2CB08BG5H8XY%2CB00EZMEHQ2%2C3110486814%2CB01MYULCEC%2CB00AAZ3THG%2CB01LP5FCP6%2CB07KZ4H9KR%2CB07BZC8LBB%2CB0785MQP8W%2CB01M4N0UCH%2CB07T2BGV5V%2CB00QV47KM0"
              target="_blank"
              ><img src="@/assets/images/packshot.png" alt=""
            /></a>
          </div>
          <div class="desktop-top__tt" :class="$mq">
            <img :src="require(`@/assets/images/` + $config.tt)" alt />
          </div>
          <!--          <div class="mask" :class="$mq">
            <img src="@/assets/images/trailer_mask.png" />
          </div>-->
          <div class="desktop-top__trailer" :class="$mq">
            <div v-if="$config.youtube.display">
              <div
                @click="ytVideo"
                class="open-trailer"
                style="position: relative"
              >
                <video
                  src="@/assets/videos/sizzle.mp4"
                  loop
                  muted
                  autoplay
                ></video>
                <!--<img src="@/assets/images/trailer/dummy.jpg" />-->
                <div class="trailer-misc">
                  <img
                    :style="[
                      ratio < 1.73
                        ? {
                            width: '40px',
                          }
                        : {
                            width: '70px',
                          },
                    ]"
                    class="play-btn"
                    src="@/assets/images/trailer/play.png"
                    alt
                  />
                  <div class="trailer-text">Trailer ansehen</div>
                </div>
              </div>
            </div>
          </div>
          <BaseKinostart :kinostart="kinostart" />
          <div class="underline-text" :class="$mq">
            Ab 30.11. als digitaler Download
          </div>
          <div class="btns_top" :class="$mq">
            <div class="amazon_top" :class="$mq">
              <a
                href="https://www.amazon.de/Gunda/dp/B09CRLZLGQ/ref=sr_1_2?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Gunda+DVD&qid=1635174223&qsid=262-1034553-2302661&s=dvd&sr=1-2&sres=B09CRLZLGQ%2CB09794X5RG%2CB01M3S2HM1%2CB00ABHQFS8%2CB00ABHQFW4%2CB017RGO61S%2CB006XEU6VA%2CB08BG5H8XY%2CB00EZMEHQ2%2CB07T2BGV5V%2CB01MPWGLUW%2CB00AAZ3THG%2CB07H2Z3PLV%2CB01M4N0UCH%2CB01MXVVDXG%2CB01LP5FCP6"
                target="_blank"
                ><img src="@/assets/images/amazon_top.png" alt=""
              /></a>
            </div>
            <div class="itunes_top" :class="$mq">
              <a
                href="https://tv.apple.com/de/movie/gunda/umc.cmc.6qm3lmiek1mvla9c400e9aljb"
                target="_blank"
                ><img src="@/assets/images/itunes_top.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /** @format */

  import { EventBus } from '@/plugins/event-bus.js'
  import Parallax from 'parallax-js'

  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      ratio: {
        type: Number,
        default: 1.78,
      },
      trailer: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      mouseEnter(e) {
        e.target.children[2].classList.add('db')
      },
      mouseLeave(e) {
        e.target.children[2].classList.remove('db')
      },
      ytVideo() {
        EventBus.$emit('open-yt')
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  .db {
    visibility: visible !important;
    animation-name: vis;
    animation-duration: 0s;
    animation-fill-mode: forwards;
  }
  @keyframes display {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes vis {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  .desktop-top {
    $self: &;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #020204;
    &.lg {
      height: 98vh;
    }
    &__wrapper {
      padding-top: 57px;
      margin-top: -1%;
      position: relative;
      display: flex;
      flex-direction: row;
      height: 100%;
      .mehrinfo {
        font-family: 'Lora';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: auto;
        bottom: 20px;
        background-color: white;
        height: 69px;
        width: 200px;
        &.lg {
          bottom: 35px;
          height: 49px;
          width: 150px;
          .text {
            font-size: 18px;
          }
        }
        .text {
          margin-top: 12px;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
        }
      }
      #{$self}__praedi {
        position: absolute;
        bottom: 16%;
        left: 2.3%;
        z-index: 10;
        width: 15%;
        img {
          width: 40%;
        }
        &.lg {
          bottom: 16%;
          width: 17%;
          left: 2.6%;
        }
        &.xl {
          width: 17%;
          left: 2.5%;
        }
        .stoerer {
          z-index: 10;
          padding-bottom: 15px;
          pointer-events: auto;
          img {
            width: 40%;
          }
          &.lg {
            width: 15%;
            right: 2%;
          }
          &.xl {
            width: 15%;
            bottom: 22%;
            right: 2%;
          }
        }
      }

      #{$self}__gewinner {
        z-index: 10;
        position: absolute;
        bottom: 6%;
        left: 2%;
        width: 15%;
        img {
          width: 100%;
        }
        &.lg {
          bottom: 8%;
          width: 19%;
          left: 2%;
        }
        &.xl {
          width: 18%;
          left: 2%;
        }
      }
      #{$self}__image {
        width: 50%;
        height: 78%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background: #020204 url($assets + 'images/top/top.jpg') center center
          no-repeat;
        background-size: contain;
        margin: 5% 0 5% 3%;
        &.lg {
          margin: 2% 0 2% 3%;
          width: 65%;
        }
      }
      #{$self}__content {
        position: relative;
        width: 50%;
        height: 100%;
        padding: 0 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &.lg {
          align-items: center;
          justify-content: center;
          padding-top: -50px;
        }
        #{$self}__tt {
          width: 80%;
          &.lg {
            width: 80%;
          }
        }
        /* @media (max-height: 891px) {
          #{$self}__tt {
            width: 50%;
          }
        } */
        #{$self}__social {
          position: absolute;
          top: 4%;
          right: 0%;
          width: 12%;
          display: flex;
          .fb {
            pointer-events: auto;
            img {
              width: 100%;
            }
            &.lg {
              width: 25%;
            }
            &.xl {
              width: 30%;
            }
          }
          .insta {
            margin-left: 20px;
            pointer-events: auto;
            img {
              width: 100%;
            }
            &.lg {
              width: 25%;
            }
            &.xl {
              width: 30%;
            }
          }
        }

        #{$self}__packshot {
          z-index: 10;
          position: absolute;
          bottom: 50px;
          right: 2%;
          width: 15%;
          pointer-events: auto;
          img {
            width: 100%;
          }
          &.lg {
            bottom: 50px;
            width: 20%;
            right: 2%;
          }
          &.xl {
            bottom: 55px;
            width: 16%;
            right: 2%;
          }
        }
        #{$self}__download-top {
          position: absolute;
          pointer-events: auto;
          width: 25%;
          img {
            width: 100%;
          }
          &.xl {
            width: 25%;
          }
        }
        .underline-text {
          color: #b1b3b6;
          width: 100%;
          text-align: center;
          font-family: Lora;
          $min_width: 300px;
          $max_width: 1024px;
          $min_font: 15px;
          $max_font: 30px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
          &.lg {
            padding-bottom: 20px;
            $min_width: 300px;
            $max_width: 1024px;
            $min_font: 12px;
            $max_font: 12px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
        }
        .btns_top {
          display: flex;
          flex-direction: row;
          margin-top: 30px;
          z-index: 9999;
          cursor: pointer;
          &.lg {
            margin-left: 20px;
            margin-top: 20px;
          }
          .amazon_top {
            pointer-events: auto;
            padding-right: 4%;
            width: 90%;
            transition: transform 0.2s;
            &:hover {
              transform: scale(1.05);
            }
            &.lg {
              width: 90%;
              padding-right: 0%;
              img {
                width: 80%;
              }
            }
          }
          .itunes_top {
            pointer-events: auto;
            padding-left: 4%;
            width: 90%;
            transition: transform 0.2s;
            &:hover {
              transform: scale(1.05);
            }
            &.lg {
              width: 90%;
              padding-left: 0;
              img {
                width: 80%;
              }
            }
          }
        }
        #{$self}__trailer {
          pointer-events: auto;
          margin-top: 3%;
          position: relative;
          width: 69%;
          margin-bottom: 1.5rem;
          &.lg {
            margin-top: 1.5rem;
            margin-bottom: 0.5rem;
            width: 70%;
          }
          cursor: pointer;
          .trailer-misc {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 111;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .trailer-text {
              font-weight: 400;
              color: white;
              font-size: 16px;
              text-transform: uppercase;
              margin-top: 0.5rem;
            }
            .trailer-quelle {
              position: absolute;
              right: 5px;
              bottom: 5px;
              font-size: 12px;
              color: white;
              line-height: 1;
            }
          }
        }
      }
    }
  }
</style>
