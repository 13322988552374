<!-- @format -->
<template>
  <div class="crew" id="crew" :class="$mq">
    <div class="crew__wrapper">
      <div class="crew-inhalt">
        <!--<img src="@/assets/images/crew_bg.jpg" />-->
        <div class="crew-text" :class="$mq">
          <div
            class="head"
            :class="$mq"
            v-scroll-reveal.stop="{
              duration: 1000,
              delay: 650,
              origin: 'bottom',
              distance: '40px',
            }"
          >
            CREW
          </div>
          <div
            class="leute"
            v-scroll-reveal.stop="{
              duration: 1000,
              delay: 850,
              origin: 'bottom',
              distance: '40px',
            }"
          >
            <div class="person" :class="$mq">
              <span>Victor Kossakovsky</span><br />
              Buch, Regie & Schnitt
            </div>
            <div class="person" :class="$mq">
              <span>Anita Rehoff Larsen</span><br />
              Produzentin
            </div>
            <div class="person" :class="$mq">
              <span>Joaquin Phoenix, Tone Grøttjord-Glenne</span><br />
              Ausführende Produzenten
            </div>
            <div class="person" :class="$mq">
              <span>Joslyn Barnes & Susan Rockefeller</span><br />
              Koproduzentinnen
            </div>
            <div class="person" :class="$mq">
              <span>Egil Haskjold Larsen & Victor Kossakovsky</span><br />
              Kamera
            </div>
            <div class="person" :class="$mq">
              <span>Alexandr Dudarev</span><br />
              Sound-Design & -Schnitt
            </div>
          </div>
          <div class="bild-crew" :class="$mq">
            <img src="@/assets/images/cast-image.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  .crew {
    $self: &;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('~@/assets/images/crew_bg.jpg');
    overflow: hidden;
    height: 656px;
    width: 100%;
    position: relative;
    font-family: 'Lora';
    &.lg {
      height: 644px;
    }
    &.xl {
      height: 644px;
    }
    .crew-text {
      margin-top: 31px;
      position: absolute;
      left: 50%;
      margin-left: -390px;
      font-family: 'Lora';
      text-align: center;
      &.xl {
        margin-top: 21px;
      }
      &.lg {
        margin-top: 21px;
      }
      .head {
        color: #b2b3b6;
        font-size: 40px;
      }
      .person {
        font-size: 14px;
        color: white;
        margin-bottom: 10px;
        span {
          font-weight: 700;
          font-size: 16px;
        }
      }
      .bild-crew {
        margin: 20px auto 0 auto;
        width: 70%;
        left: 50%;
        img {
          width: 100%;
        }
      }
    }
  }
</style>
