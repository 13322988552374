<!-- @format -->
<template>
  <div class="base-kinofinder" v-if="$config.kinoservice.display" :class="$mq">
    <ul :class="$mq">
      <li :class="$mq">
        <a
          :class="$mq"
          :href="$config.kinoservice.finder + $config.kinoservice.slug"
          target="_blank"
          >{{ $config.kinoservice.finder_text }}</a
        >
      </li>
      <li :class="$mq" v-if="$config.kinoservice.alert_text !== ''">
        <a
          :class="$mq"
          :href="$config.kinoservice.alert + $config.kinoservice.id"
          target="_blank"
          >{{ $config.kinoservice.alert_text }}</a
        >
      </li>
      <mq-layout mq="lg+">
        <li class="share" v-if="$config.kinoservice.share !== ''">
          <a href="#" @mouseover="hover = true" @mouseleave="hover = false"
            >Teilen</a
          >
          <div
            class="share__btns"
            :class="{ hovered: hover }"
            @mouseover="hover = true"
            @mouseleave="hover = false"
          >
            <a
              target="_blank"
              :href="`https://www.facebook.com/sharer/sharer.php?u=${$config.url}`"
              class="share__item share__fb"
              ><img src="@/assets/images/social/fb_plain.png" alt="" />
            </a>
            <a
              :href="`https://twitter.com/intent/tweet?text=${$config.twitter.text}`"
              target="_blank"
              class="share__item share__twitter"
            >
              <img src="@/assets/images/social/twitter_plain.png" alt="" />
            </a>
          </div>
        </li>
      </mq-layout>
    </ul>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        hover: false,
      }
    },
  }
</script>

<style lang="scss">
  .base-kinofinder {
    font-family: 'Lora';
    position: relative;
    height: $header-height;
    &.sm,
    &.md {
      height: auto;
    }
    ul {
      margin: 0;
      padding: 0;
      text-align: center;
      list-style-type: none;
      line-height: $header-lineheight;
      display: flex;
      &.sm,
      &.md {
        flex-direction: column;
      }

      .hovered {
        transform: translateY(-$header-height);
      }

      .share {
        position: relative;
        &__btns {
          position: absolute;
          padding-left: 10px;
          right: -50px;
          bottom: -$header-height;
          height: $header-height;
          display: flex;
          flex-direction: row;
          transition: transform 0.3s ease-in-out;
          width: 120px;
          .share__item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
          .share__fb {
            background-color: $facebook;
            &:hover {
              background-color: darken($facebook, 5%);
            }
          }
          .share__twitter {
            background-color: $twitter;
            &:hover {
              background-color: darken($twitter, 5%);
            }
          }
        }
      }
      li {
        height: $header-height;
        display: inline-block;
        text-align: left;
        &.sm,
        &.md {
          height: auto;
        }
        a {
          text-decoration: none;
          text-transform: uppercase;
          height: $header-height;
          line-height: $header-height;
          color: $header-link;
          font-weight: 500;
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 16px;
          $max_font: $header-fontsize;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
          &:hover {
            color: $header-link-hover;
          }
          &.sm,
          &.md {
            font-weight: 600;
            margin-bottom: 10px;
            $min_width: 300px;
            $max_width: 1023px;
            $min_font: 20px;
            $max_font: 38px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
        }
        &:not(:last-child) {
          padding-right: $header-link-padding;
        }
      }
    }
  }
</style>
