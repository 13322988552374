<!-- @format -->
<template>
  <div class="base-kinostart" :class="$mq">
    <!-- eslint-disable-next-line -->
    <div class="text" :class="$mq" v-html="kinostart"></div>
  </div>
</template>
<script>
  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
    },
  }
</script>
<style lang="scss">
  .base-kinostart {
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-weight: 400;
    font-family: 'Lora', serif;
    &.sm,
    &.md {
      padding-top: 10px;
      background-color: white;
    }
    .text {
      &.lg {
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 20px;
        $max_font: 22px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
      &.xl,
      &.xxl {
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 30px;
        $max_font: 44px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
      &.sm,
      &.md {
        font-weight: 500;
        $min_width: 300px;
        $max_width: 1023px;
        $min_font: 22px;
        $max_font: 55px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
    }
  }
</style>
