<!-- @format -->
<template>
  <mq-layout :mq="['sm', 'md']">
    <div class="mobile-top" id="mobile-top">
      <img src="@/assets/images/bg_top_mobile.jpg" alt="" />
    </div>
    <div class="mobile-top__kinostart" v-html="kinostart"></div>
    <div class="mob-underline">Ab 30.11. als digitaler Download</div>
  </mq-layout>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      showKinofinder: {
        type: Boolean,
        default: true,
      },
    },
  }
</script>

<style lang="scss">
  .no-scroll {
    overflow: hidden;
    height: 100vh;
    scrollbar-width: 0;
  }
  .fullScreen {
    height: 100vh;
  }
  .mobile-top {
    $self: &;
    position: relative;
    margin-top: 50px;
    &__kinostart {
      color: white;
      width: 100%;
      text-align: center;
      font-family: Lora;
      $min_width: 300px;
      $max_width: 1024px;
      $min_font: 24px;
      $max_font: 60px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
  }
  .mob-underline {
    color: #b1b3b6;
    width: 100%;
    text-align: center;
    font-family: Lora;
    $min_width: 300px;
    $max_width: 1024px;
    $min_font: 15px;
    $max_font: 40px;
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
  }
  .invi-button {
    cursor: pointer;
    pointer-events: auto;
    z-index: 20;
    a {
      width: 350px;
      height: 300px;
    }
    &.md {
      position: absolute;
      bottom: -10%;
      right: 0;
      width: 350px;
      height: 300px;
    }
    &.sm {
      position: absolute;
      bottom: 10%;
      right: 0;
      width: 250px;
      height: 400px;
    }
  }
  .flickity-viewport {
    .flickity-slider {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
